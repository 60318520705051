import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Table, Popup } from 'semantic-ui-react';
import { FiPlusSquare, FiEye } from 'react-icons/fi';

import Icon from '../Icon';

import { convertMilSec } from '../../utils/convertMilSec';

import style from './UserStatus.module.scss';

import UserInformation from 'layout/Sidebar/Users/UserItem';

import { useGlobalContext } from 'contexts';
import { EVENT_TYPE } from 'utils/constants';

interface User {
  id: string;
  name: string;
  preferredUsername: string;
  status: string;
  email?: string;
  picture: string;
  updatedAt: string;
  belongsToProject: boolean;
  totalDays: [];
  total: number;
  dateLastEvent: Date;
  customTotalDays: [];
}

interface UserOnAddClickFunc {
  (userId: string, date: 'string'): void;
}

interface UserOnDownloadDailyClickFunc {
  (userId: string, date: string): void;
}

interface UserOnAttendanceDetailClickFunc {
  (userId: string, date: string): void;
}

interface UserOnDownloadClickFunc {
  (userId: string): void;
}

interface UserStatusComponent {
  user: User;
  globalUserIsAdmin: boolean,
  globalUserIsManager: boolean,
  globalUserId: string,
  selectedClass: string;
  onClick: any;
  onAddClick?: UserOnAddClickFunc;
  onDownloadDailyClick?: UserOnDownloadDailyClickFunc;
  onDownloadClick?: UserOnDownloadClickFunc;
  onAttendanceDetailClick?: UserOnAttendanceDetailClickFunc;
  week?: Dayjs[];
  actions?: Array<any>;
}

export default function UserStatus(props: UserStatusComponent) {
  const {
    user,
    onClick,
    globalUserIsAdmin,
    globalUserIsManager,
    globalUserId,
    week,
    onAddClick,
    onDownloadDailyClick,
    onDownloadClick,
    onAttendanceDetailClick
  } = props;
  const [state] = useGlobalContext();
  const { contentToShow } = state;
  function isView(tabId: string) {
    return contentToShow === tabId;
  }

  const [customTotalActAttendance, setCustomTotalActAttendance] = useState({
    index: -1,
    millisecondsToAdd: 0,
  });

  useEffect(() => {
    const todayIndex = week?.findIndex((day) => dayjs(day).isSame(dayjs(), 'day')) ?? -1;

    setCustomTotalActAttendance(() => ({
      index: todayIndex,
      millisecondsToAdd: user.dateLastEvent ? dayjs().diff(dayjs(user.dateLastEvent)) : 0,
    }));
  }, []);

  function getWeekData(currentUser: any): Array<number> {
    if (isView('timesheet')) {
      return currentUser.customTotalActDays || [];
    }
    return currentUser.customTotalDays || [];
  }

  function getTotalColumn(currentUser: any): JSX.Element | null {
    if (isView('timesheet')) {
      return currentUser.totalAct >= 0 ? (
        <AttendaceValue value={convertMilSec(currentUser.totalAct)} userId={currentUser.id} />
      ) : null;
    }
    return currentUser.total + customTotalActAttendance.millisecondsToAdd >= 0
      ? <AttendaceValue value={
        user.status === EVENT_TYPE.CHECK_IN
          ? convertMilSec(currentUser.total + customTotalActAttendance.millisecondsToAdd)
          : convertMilSec(currentUser.total)} />
      : null;
  }
  function getDownloadIconColumn(currentUser: any): JSX.Element | null {
    return isView('timesheet') && user.belongsToProject && convertMilSec(currentUser.totalAct)
      ? <Table.Cell
          width='1'
          className={`${style.cell} ${style.pressable}`}
        >
          <div className={style.iconContainer}>
            <Popup content='Export report' trigger={
              <div>
                <Icon.Report
                  className={`${style.downloadIcon}`}
                  onClick={() => onDownloadClick && onDownloadClick(user.id)}
                  size={25}
                />
              </div>} 
            />
          </div>
      </Table.Cell>
      : null;
  }

  function getColumnDate(weekToAttendance: Dayjs[], index: number): string {
    if (!weekToAttendance.length) return dayjs().format('YYYY-MM-DD');
    return weekToAttendance[index].format('YYYY-MM-DD');
  }

  const AttendaceValue = ({ value, userId, date, pressable, showCheckIn }: any) => (
    <Table.Cell
      {...({ textAlign: 'center' })}
      className={`${style.cell} ${pressable || (!pressable && isView('timesheet')) ? style.pressable : ''} `}
    >
      {pressable && isView('timesheet') && user.belongsToProject && (
        <div className={style.iconContainer}>
          {(globalUserIsAdmin || globalUserIsManager || userId === globalUserId) &&
          (
            <Popup content='Add record' trigger={
              <FiPlusSquare
                className={`${style.plusIcon}`}
                onClick={() => onAddClick && onAddClick(userId, date)}
                size={25}
              />} 
            />)
          }
          {value && (
            <Popup content='Export report' trigger={
              <div className={style.reportContainer}>
                <Icon.Report
                  className={style.downloadDailyIcon}
                  onClick={() => onDownloadDailyClick && onDownloadDailyClick(userId, date)}
                  size={25}
                />
              </div>} 
            />
          )}
        </div>
      )}
      {pressable && isView('attendance') && (
        <div className={style.iconContainer}>
          <Popup content='See attendance records' trigger={
            <FiEye
              className={style.detailIcon}
              onClick={() => onAttendanceDetailClick && onAttendanceDetailClick(userId, date)}
              size={25}
            />} 
        />
        </div>
      )}
      <span className={`${pressable ? style.label : ''} ${showCheckIn ? style.available : ''}`}>{value}</span>
    </Table.Cell>
  );

  return (
    <Table.Row
      onClick={onClick}
      className={(user.belongsToProject && isView('timesheet')) || isView('attendance')
        ? style.memberTableTr : style.disabledMemberTableTr}
    >
      <Table.Cell className={style.userInformationCell}>
        <UserInformation className={style.removeUserStyles} {...user} />
      </Table.Cell>
      {_.map(getWeekData(user), (valueDay, i: number) => {
        const showCheckIn = i === customTotalActAttendance.index && user.status === EVENT_TYPE.CHECK_IN && isView('attendance');
        const attendanceMilliseconds = showCheckIn
          ? convertMilSec(valueDay + customTotalActAttendance.millisecondsToAdd)
          : convertMilSec(valueDay)
        return (
          <AttendaceValue
            key={i}
            value={attendanceMilliseconds}
            userId={user.id}
            date={getColumnDate(week || [], i)}
            pressable={(isView('timesheet') && user.belongsToProject) || isView('attendance')}
            showCheckIn={showCheckIn}
          />
        )
      })}
      {getTotalColumn(user)}
      {getDownloadIconColumn(user)}
    </Table.Row>
  );
}

UserStatus.defaultProps = {
  week: [],
  onAddClick: null,
  onDownloadDailyClick: null,
  onDownloadClick: null,
  onAttendanceDetailClick: null,
  actions: [],
};
